<template>
  <div class="pa-4">
    <v-container fluid>
      <h2 text color="black" class="headline">保管一覧</h2>
      <h3 text color="black" class="mt-2">保管ホルター心電計の一覧（現在の保管数：{{ holterStockQuantityKeepedTotal }}）</h3>
      <v-row dense>
        <v-col
          cols="12" md="12" sm="12" xl="12"
        >
          <v-toolbar
            rounded
            color="primary"
            flat
            dark
            class="pl-2 pr-2 pt-6 pb-3"
            height="auto"
          >
            <v-row dense class="align-center">

              <v-col cols="12" sm="2">
                <v-select
                  dense
                  hide-details
                  eager
                  v-model="searchSelectedField"
                  label="検索フィールド"
                  :items="searchFields"
                  @change="subscribeItems"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="6" v-if="searchSelectedField == SEARCH_FIELD_NAME.DEVICE_ID">
                <v-text-field
                  dense
                  label="前方一致検索キーワード"
                  outlined
                  clearable
                  hide-details
                  v-model="searchKeyword"
                  prepend-inner-icon="search"
                  @blur="subscribeItems"
                  @keyup.enter="subscribeItems"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="4" v-if="searchSelectedField == SEARCH_FIELD_NAME.DEVICE_ID">
              </v-col>

              <v-col cols="12" sm="3"  v-if="searchSelectedField == SEARCH_FIELD_NAME.DATE_RANGE">
                <v-select
                  dense
                  hide-details
                  eager
                  v-model="searchDateSelectedField"
                  label="日付検索項目"
                  :items="searchDateFields"
                  @change="subscribeItems"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="3" v-if="searchSelectedField == SEARCH_FIELD_NAME.DATE_RANGE">
                <v-text-field
                  dense
                  hide-details
                  type="date"
                  v-model="searchBeginKeepStart"
                  clearable
                  label="検索開始日"
                  :disabled="searchDateSelectedField == SEARCH_DATE_FIELD_NAME.UNSPECIFIED"
                />
              </v-col>

              <div v-if="searchSelectedField == SEARCH_FIELD_NAME.DATE_RANGE">
                &nbsp;〜&nbsp;
              </div>

              <v-col cols="12" sm="3" v-if="searchSelectedField == SEARCH_FIELD_NAME.DATE_RANGE">
                <v-text-field
                  dense
                  hide-details
                  type="date"
                  v-model="searchBeginKeepEnd"
                  clearable
                  label="検索終了日"
                  :disabled="searchDateSelectedField == SEARCH_DATE_FIELD_NAME.UNSPECIFIED"
                />
              </v-col>

              <v-col cols="12" sm="3">
                <v-checkbox
                  dense
                  hide-details
                  v-model="notReceivedActivityLogFlag"
                  label="行動記録用紙未受付"
                  @change="subscribeItems"
                />
              </v-col>

              <v-col cols="12" sm="3">
                <v-checkbox
                  dense
                  hide-details
                  v-model="uploadErrorFlag"
                  label="アップロードエラー"
                  @change="subscribeItems"
                />
              </v-col>

              <v-col cols="12" sm="6" style="display: flex; justify-content: flex-end; align-items: center;">
                <div class="text-caption mr-2">選択したデバイスに対する一括処理</div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      icon
                      @click="disposeSelectedItem()"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon class="mx-3">delete</v-icon>
                    </v-btn>
                  </template>
                  <span>一括廃棄記録</span>
                </v-tooltip>
              </v-col>

            </v-row>
          </v-toolbar>

        </v-col>
      </v-row>

      <v-row dense v-if="isOverQueryLimit">
        <v-col cols="12">
          <v-alert
            class="ma-0"
            dense
            dismissible
            outlined
            v-model="isOverQueryLimit"
            type="warning"
            prominent
          >
            <span v-for="(msg, index) in msgsOverQueryLimit" :key="index">
            {{ msg }}<br />
            </span>
          </v-alert>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">

          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page.sync=selectedLinesPerPage
            show-select
            v-model="selected"
            hide-default-footer
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            @update:sort-by="updateSortBy"
            @update:sort-desc="updateSortDesc"
            fixed-header
            height="700"
          >
            <template v-slot:top="{ pagination, options, updateOptions }">
              <v-data-footer
                :pagination="pagination"
                :options="options"
                @update:options="updateOptions"
                items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                :items-per-page-options="linesPerPageList"
                class="border-less"
              />
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="viewItemForm(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon class="mx-3">remove_red_eye</v-icon>
                  </v-btn>
                </template>
                <span>詳細表示</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="editItemForm(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon class="mx-3">edit</v-icon>
                  </v-btn>
                </template>
                <span>編集</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="disposeItem(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon class="mx-3">delete</v-icon>
                  </v-btn>
                </template>
                <span>廃棄記録</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <device-status-chip :item="item" />
            </template>
            <!-- 心電計アップロード結果 -->
            <template v-slot:[`item.device_upload_result`]="{ item }">
              <div class="red--text" v-if="item.device_upload_result=='error'">
                ×
                <v-btn icon color="red" @click="$root.$alert('エラー', item.device_upload_error_info.msg );">
                  <v-icon small>mdi-message-text-outline</v-icon>
                </v-btn>
              </div>
              <div v-else>{{ item.device_upload_result }}</div>
            </template>
            <!-- 行動記録用紙アップロード結果 -->
            <template v-slot:[`item.log_upload_result`]="{ item }">
              <div class="red--text" v-if="item.log_upload_result=='error'">
                ×
                <v-btn icon color="red" @click="$root.$alert('エラー', item.log_upload_error_info.msg );">
                  <v-icon small>mdi-message-text-outline</v-icon>
                </v-btn>
              </div>
              <div v-else>{{ item.log_upload_result }}</div>
            </template>
            <!-- 解析オプション -->
            <template v-slot:item.analysis_opinion_type="{ item }">
              <div style="white-space: pre;">{{ item.analysis_opinion_type }}</div>
            </template>
            <!-- 心電計型式 -->
            <template v-slot:item.ecg_type="{ item }">
              {{ ecgType2Text(item) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <inventory-dialog
      v-if="mode != 'none'"
      v-model="inventoryDialogModel"
      :mode="mode"
      :item="currentItem"
      :dialog-title="dialogTitle"
      @closed="onDialogClosed"
      @not-deleted="itemNotDeleted"
    />

  </div>
</template>

<script>
import dateformat from 'dateformat';
import InventoryDialog from '@/components/InventoryDialog.vue';
import DeviceStatusChip from '@/components/DeviceStatusChip.vue';
import AnalysisOpinionType from '@/mixins/AnalysisOpinionType.js';

let unsubscribeItems = null;
let unsubscribeNumOfKeepeds = null;
let itemsRef = null;

const SEARCH_FIELD_NAME = {
  DEVICE_ID: 'デバイスID',
  DATE_RANGE: '各種日付範囲'
};
const SEARCH_DATE_FIELD_NAME = {
  BEGIN_KEEP: '保管開始日',
  UNSPECIFIED: '指定なし'
};
const UPLOAD_RESULT = {
  OK: '○',
  NG: 'error',
  NONE: null,
};


export default {
  name: 'Disposals',
  components: {
    InventoryDialog,
    DeviceStatusChip,
  },
  mixins: [
    AnalysisOpinionType,
  ],
 data() {
    return {
      inventoryDialogModel: false,
      headers: [
        {
          text: '操作',
          align: 'left',
          sortable: false,
          value: 'action',
          width: '140px',
        },
        {
          text: '保管開始日時',
          align: 'left',
          sortable: true,
          value: 'keeped',
          width: '150px',
        },
        {
          text: '経過期間',
          align: 'left',
          sortable: true,
          value: 'keep_period',
          width: '100px',
        },
        {
          text: 'デバイスID',
          align: 'left',
          sortable: true,
          value: 'device_id',
          width: '110px',
        },
        {
          text: '型式',
          align: 'left',
          sortable: true,
          value: 'ecg_type',
          width: '80px',
        },
        {
          text: '状態',
          align: 'left',
          sortable: true,
          value: 'status',
          width: '60px',
        },
        // {
        //   text: '解析オプション',
        //   align: 'left',
        //   sortable: true,
        //   value: 'analysis_opinion_type',
        //   width: '90px',
        // },
        {
          text: '解析ステータス',
          align: 'left',
          sortable: true,
          value: 'analysis_status',
          width: '140px',
        },
        {
          text: '受付日時',
          align: 'left',
          sortable: true,
          value: 'collected',
          width: '150px',
        },
        {
          text: '心-受付',
          align: 'left',
          sortable: true,
          value: 'device_collected',
          width: '150px',
        },
        {
          text: '行-受付',
          align: 'left',
          sortable: true,
          value: 'log_collected',
          width: '150px',
        },
        {
          text: '心-UP日時',
          align: 'left',
          sortable: true,
          value: 'device_uploaded',
          width: '150px',
        },
        {
          text: '結果',
          align: 'left',
          sortable: true,
          value: 'device_upload_result',
          width: '80px',
        },
      ],
      items: [],
      currentItem: {},
      mode: 'none',
      searchKeyword: '',
      accessTypeModel: 'all',
      searchSelectedField: SEARCH_FIELD_NAME.DATE_RANGE,
      searchFields: [
        SEARCH_FIELD_NAME.DATE_RANGE,
        SEARCH_FIELD_NAME.DEVICE_ID
      ],
      searchDateSelectedField: SEARCH_DATE_FIELD_NAME.BEGIN_KEEP,
      searchDateFields: [
        SEARCH_DATE_FIELD_NAME.BEGIN_KEEP,
        SEARCH_DATE_FIELD_NAME.UNSPECIFIED
      ],
      uploadErrorFlag: false,
      notReceivedActivityLogFlag: false,
      searchBeginKeepStart: dateformat(
        function(){
          const now = new Date();
          return new Date(now.getFullYear(), now.getMonth() - 2, now.getDate())
        }(), 'yyyy-mm-dd'
      ),
      searchBeginKeepEnd: '',
      dialogTitle: '保管一覧',
      holterStockQuantityKeepedTotal: null,
      selected: [],
      selectedLinesPerPage: 10,
      linesPerPageList: [10, 30, 50],
      isOverQueryLimit: false,
      sortBy: ['keeped'],
      sortDesc: [true],
    };
  },
  computed: {
    SEARCH_FIELD_NAME() {
      return SEARCH_FIELD_NAME;
    },
    SEARCH_DATE_FIELD_NAME() {
      return SEARCH_DATE_FIELD_NAME;
    }
  },
  methods: {
    viewItemForm(item) {
      // console.log('viewItemForm', item);
      this.mode = 'view';
      this.currentItem = item;
      this.inventoryDialogModel = true;
      this.dialogTitle = 'デバイス情報';
    },
    editItemForm(item) {
      this.mode = 'edit';
      this.currentItem = item;
      this.inventoryDialogModel = true;
      this.dialogTitle = 'デバイス情報';
    },
    onDialogClosed() {
      // 削除完了後に呼び出されるCallback
      this.mode = 'none';
      this.currentItem = null;
      this.inventoryDialogModel = false;
    },
    itemNotDeleted() {
      // 削除完了後に呼び出されるCallback
      this.mode = 'none';
      // console.log('item-not-deleted');
    },
    timestampToString(timestamp, format='yyyy/mm/dd HH:MM'){
      try {
        return timestamp ? dateformat(new Date(timestamp.seconds * 1000), format): null;
      } catch (e) {
        return null;
      }
    },
    subscribeItems() {
      itemsRef = this.$db.collection('devices');
      // 購読中は一旦解除した上で対応する。
      if (unsubscribeItems) unsubscribeItems();
      this.searchKeyword = this.searchKeyword ? this.searchKeyword.trim(): '';

      let query = itemsRef
        .where('deleted', '==', null)
        .where('status', 'in', [6, 7, 11]);

      // 所属でフィルタ
      if (!this.$store.state.user.role.startsWith('management')) {
        // 販売組織スタッフの場合、所属するセンターのデータのみ
        query = query.where('reception_center_id', '==', this.$store.state.user.org_id);
      }

      // 必須検索の日付指定検索
      if (this.searchSelectedField === SEARCH_FIELD_NAME.DATE_RANGE
        && this.searchDateSelectedField === SEARCH_DATE_FIELD_NAME.BEGIN_KEEP
      ) {
        if (this.searchBeginKeepStart !== null && this.searchBeginKeepStart !== '') {
          let startAt = new Date(`${this.searchBeginKeepStart.replaceAll('-', '/')} 00:00:00`);
          query = query.where('keeped', ">=", startAt);
        }
        if (this.searchBeginKeepEnd !== null && this.searchBeginKeepEnd !== '') {
          let endAt = new Date(`${this.searchBeginKeepEnd.replaceAll('-', '/')} 23:59:59`);
          query = query.where('keeped', "<=", endAt);
        }
      }

      if (this.searchSelectedField === SEARCH_FIELD_NAME.DEVICE_ID
        && this.searchKeyword.length > 0
      ) {
        // 検索キーワードで前方検索
        let startAt = this.searchKeyword;
        let endAt = this.searchKeyword + '\uffff';
        let searchFieldsValues = {
          'デバイスID':'device_id',
        };
        // 指定したキーで前方検索
        query = query
          .orderBy(searchFieldsValues[this.searchSelectedField])
          .startAt(startAt).endAt(endAt);
      }

      // 上限（上限に達したことを判定するために＋１件する）
      const queryLimit = this.$store.state.configs.query_limit;
      this.msgsOverQueryLimit = [
        `表示対象データが${queryLimit}件を超えました。${queryLimit + 1}件以上は表示されません。${queryLimit}件以内にするためには各種検索条件を調整してください。`,
        '※「行動記録用紙未受付」「アップロードエラー」では調整できません。'
      ];
      query = query.limit(queryLimit + 1);

      unsubscribeItems = query.onSnapshot((querySnapshot) => {
        // 最初および変更時の読み取り
        let listCount = 0;
        this.isOverQueryLimit = false;
        let items = [];
        let subscribedDeviceIds = [];
        const now = new Date().getTime();

        // クエリ上限を超えた場合
        if (querySnapshot.docs.length > queryLimit) this.isOverQueryLimit = true;

        querySnapshot.forEach((doc) => {

          // クエリ件数が上限を超えた場合は終了
          listCount = listCount + 1;
          if (listCount > queryLimit) return;

          let data = doc.data();

          data.created = this.timestampToString(data.created);
          data.modified = this.timestampToString(data.modified);
          data.collected = this.timestampToString(data.collected, 'yyyy/mm/dd HH:MM');
          data.produced = data.produced ? dateformat(new Date(data.produced.seconds * 1000), 'yyyy/mm/dd'): '';
          data.expiration_date = data.expiration_date ? dateformat(new Date(data.expiration_date.seconds * 1000), 'yyyy/mm/dd'): '';
          data.id = doc.id;
          data.analysis_status = '';
          switch (data.status) {
            case 6:
              data.analysis_status = '解析中';
              break;
            case 7:
              data.analysis_status = '完了';
              break;
            case 11:
              data.analysis_status = 'エラー';
              break;
          }
          // 経過期間
          if ('keeped' in data && data.keeped != null) {
            data.keep_period = parseInt((now - data.keeped.seconds * 1000) / (24 * 3600 * 1000));
            if (data.keep_period === 0) {
              data.keep_period = '1日未満';
            } else {
              data.keep_period = data.keep_period + ' 日';
            }
            data.keeped = this.timestampToString(data.keeped, 'yyyy/mm/dd HH:MM');
          }

          data.device_collected = this.timestampToString(data.device_collected);
          data.log_collected = this.timestampToString(data.log_collected);

          // 心電図アップロード関連の編集
          if ('device_uploaded' in data && data.device_uploaded != null) {
            data.device_uploaded = this.timestampToString(data.device_uploaded);
            data.device_upload_result = UPLOAD_RESULT.OK;
          } else if (
            'device_upload_error_info' in data && data.device_upload_error_info != null
            && 'datetime' in data.device_upload_error_info && data.device_upload_error_info.datetime != null
          ) {
            data.device_uploaded = this.timestampToString(data.device_upload_error_info.datetime);
            data.device_upload_result = UPLOAD_RESULT.NG;
          } else {
            data.device_uploaded = null;
            data.device_upload_result = UPLOAD_RESULT.NONE;
          }

          // 行動記録用紙アップロード関連の編集
          if ('log_uploaded' in data && data.log_uploaded != null) {
            data.log_uploaded = this.timestampToString(data.log_uploaded);
            data.log_upload_result = UPLOAD_RESULT.OK;
          } else if (
            'log_upload_error_info' in data && data.log_upload_error_info != null
            && 'datetime' in data.log_upload_error_info && data.log_upload_error_info.datetime != null
          ) {
            data.log_uploaded = this.timestampToString(data.log_upload_error_info.datetime);
            data.log_upload_result = UPLOAD_RESULT.NG;
          } else {
            data.log_uploaded = null;
            data.log_upload_result = UPLOAD_RESULT.NONE;
          }

          if (!this.isHitExtendedSearchCondition(
            this.notReceivedActivityLogFlag,
            this.uploadErrorFlag,
            data
          )) {
            return;
          }

          // 患者情報取得
          data['analysis_opinion_type'] = '';
          if ('patient_id' in data && data.patient_id) {
            // 患者情報がある場合
            this.$db.collection('patients').doc(data.patient_id).get().then(async (patientDoc) => {
              if (!patientDoc.exists) return;
              const patientData = patientDoc.data();
              // 解析オプションの格納
              data['analysis_opinion_type'] = this.getDisplayAnalysisOpinionTypeString(patientData, data.status, this.$store.state.customerOrgsDict[data.order_org_id]);
            }).catch((error) => {
              // 運営主体以外は、存在しない患者／スタッフ情報にアクセスするとFirestoreのルールによりエラーとなる
              console.warn(data.patient_id, error);
            });
          }

          subscribedDeviceIds.push(data.device_id);
          items.push(data);
        });

        // 一覧に無い選択済みデバイスは選択を解除する
        this.selected = this.selected.filter((device) => {
          return subscribedDeviceIds.includes(device.device_id);
        });

        this.items = items;
      }, (e) => {
        console.log('error', e);
        this.items = [];
      });
    },
    subscribeNumOfKeepeds() {
      itemsRef = this.$db.collection('staff_orgs');

      // 購読中は一旦解除した上で対応する。
      if (unsubscribeNumOfKeepeds) unsubscribeNumOfKeepeds();

      // 所属でフィルタ
      if (this.$store.state.user.role.startsWith('management')) {
        // 運営主体スタッフの場合、全ての受付センター
        let query = itemsRef
          .where('deleted', '==', null)
          .where('type', '==', 'reception')
        unsubscribeNumOfKeepeds = query
          .onSnapshot((querySnapshot) => {
              // 最初および変更時の読み取り
              let holterStockQuantityKeepedTotal = 0;
              querySnapshot.forEach((doc) => {
                let data = doc.data();
                if (data.deleted == null) {
                  if ('holter_stock_quantity_keeped' in data && data.holter_stock_quantity_keeped != null) {
                    holterStockQuantityKeepedTotal = holterStockQuantityKeepedTotal + data.holter_stock_quantity_keeped;
                  }
                }
              });
              this.holterStockQuantityKeepedTotal = holterStockQuantityKeepedTotal;
              //console.log('onSnapshot', this.holterStockQuantityKeepedTotal);
          }, (e) => {
            console.log('error', e);
            this.holterStockQuantityKeepedTotal = null;
          });
      } else {
        // 販売組織スタッフの場合、所属するセンターのデータのみ
        let query = itemsRef.doc(this.$store.state.user.org_id);
        unsubscribeNumOfKeepeds = query
          .onSnapshot((doc) => {
            const data = doc.data();
            if ('holter_stock_quantity_keeped' in data && data.holter_stock_quantity_keeped != null) {
              this.holterStockQuantityKeepedTotal = data.holter_stock_quantity_keeped;
            }
            //console.log('onSnapshot', this.holterStockQuantityKeepedTotal);
          }, (e) => {
            console.log('error', e);
            this.holterStockQuantityKeepedTotal = null;
          });
      }
    },
    isHitExtendedSearchCondition(
      notReceivedActivityLogFlag,
      uploadErrorFlag,
      deviceData
    ) {
      /**
       * 行動記録用紙受付
       */
      if (notReceivedActivityLogFlag == true
        && deviceData.log_collected !== null
      ) {
        return false
      }
      /**
       * 各受付状態
       */
      const isError = (
        deviceData.device_upload_result === UPLOAD_RESULT.NG
        || deviceData.log_upload_result === UPLOAD_RESULT.NG
      );
      if (uploadErrorFlag === true && !isError) {
        return false;
      }

      return true;
    },
    // 廃棄記録（一括）
    async disposeSelectedItem() {
      console.log('disposeSelectedItem', this.selected);
      const result = await this.$root.$confirm(`確認`, `選択された全てのデバイスに対して一括で廃棄記録しますか？`);
      if (!result) return;
      for (const item of this.selected) {
        await this.disposeItemBody(item);
      }
    },
    // 廃棄記録（個別）
    async disposeItem(item) {
      console.log('disposeItem', item);
      const result = await this.$root.$confirm(`確認`, `このデバイス ${item.device_id} の廃棄を記録しますか？`);
      if (!result) return;
      await this.disposeItemBody(item);
    },
    // 廃棄記録本体
    async disposeItemBody(item) {
      try {
        const deviceId = item.device_id;

        // デバイスデータ取得
        const docRef = this.$db.doc('devices/' + deviceId);
        const doc = await docRef.get();
        if (!doc.exists) {
          await this.$root.$alert('エラー', `そのようなデバイス ${deviceId} は存在しません。`);
          return;
        }
        const deviceData = doc.data();
        if (![6, 7, 11].includes(deviceData.status)) {
          await this.$root.$alert('エラー', `このデバイス ${deviceId} は保管状態にありません。`);
          return;
        }

        if (!deviceData.device_collected) {
          // 心電計の受付記録がされていない場合は、廃棄記録できない
          await this.$root.$alert('エラー', '心電計が受付されていないため廃棄記録できません。');
          return;
        }

        // デバイスデータ更新
        let updateData = {
          status: 9,
          disposed: this.$firebase.firestore.FieldValue.serverTimestamp(),
          disposed_staff_id: this.$store.state.user.id,
          modified:  this.$firebase.firestore.FieldValue.serverTimestamp(),
        }
        await docRef.update(updateData);
        await this.writeLog(['device_dispose'], `廃棄記録しました。`, item); // 操作記録

        // 該当デバイス受付センターの保管数を減らし、廃棄数を増やす
        const refReceptionCenter = this.$db.collection('staff_orgs').doc(deviceData.reception_center_id);
        await refReceptionCenter.update({ holter_stock_quantity_keeped: this.$firebase.firestore.FieldValue.increment(-1) });
        await refReceptionCenter.update({ holter_stock_quantity_disposed: this.$firebase.firestore.FieldValue.increment(1) });
      } catch (e) {
        console.error(e);
        this.$root.$alert('エラー', '廃棄記録に失敗しました。' + e);
      }
    },
    // 紛失記録（一括）
    async loseSelectedItem() {
      console.log('loseSelectedItem', this.selected);
      const result = await this.$root.$confirm(`確認`, `選択された全てのデバイスに対して一括で紛失記録しますか？`);
      if (!result) return;
      for (const item of this.selected) {
        await this.loseItemBody(item);
      }
    },
    // 紛失記録（個別）
    async loseItem(item) {
      console.log('loseItem', item);
      const result = await this.$root.$confirm(`確認`, `このデバイス ${item.device_id} の紛失を記録しますか？`);
      if (!result) return;
      this.loseItemBody(item);
    },
    // 紛失記録本体
    async loseItemBody(item) {
      try {
        const deviceId = item.device_id;

        // デバイスデータ取得
        const docRef = this.$db.doc('devices/' + deviceId);
        const doc = await docRef.get();
        if (!doc.exists) {
          await this.$root.$alert('エラー', `そのようなデバイス ${deviceId} は存在しません。`);
          return;
        }
        const deviceData = doc.data();

        // デバイスデータ更新
        const updateData = {
          status: 10,
          lost: this.$firebase.firestore.FieldValue.serverTimestamp(),
          is_lost:  true,
          lost_staff_id: this.$store.state.user.id,
          modified:  this.$firebase.firestore.FieldValue.serverTimestamp(),
        }
        await docRef.update(updateData);
        await this.writeLog(['device_lose'], `紛失記録しました。`, item); // 操作記録

        // 該当デバイス受付センターの保管数を減らす
        const refReceptionCenter = this.$db.collection('staff_orgs').doc(deviceData.reception_center_id);
        await refReceptionCenter.update({ holter_stock_quantity_keeped: this.$firebase.firestore.FieldValue.increment(-1) });
      } catch (e) {
        this.$root.$alert('エラー', '紛失記録に失敗しました。' + e);
        console.error(e);
      }
    },
    isSwitchingUser() {
      // superUserが存在しない場合は、なりすまし不可
      if (!this.$store.state.superUser) {
        return false;
      }
      // ログイン中のユーザーIDと、superUserのIDが同一の場合はなりすましではない
      if (this.$store.state.user.id == this.$store.state.superUser.id) {
        return false;
      }
      return true;
    },
    async writeLog(tags, message, item, option = null) {
      const deviceType = item.type == 'dongle' ? '通信用ドングル／ソフト' : 'ホルター心電計';
      message = `デバイス：${deviceType} (${item.device_id})：${message}`
      await this.$functions.log({ tags, message, item: option });
    },
    updateSortBy(v) {
      this.sortBy = v;
    },
    updateSortDesc(v) {
      this.sortDesc = v;
    },
    ecgType2Text(v) {
      if (/^T-[0-9]{6}$/.test(v.device_id)) {
        return this.$store.state.ecgTypes[1];
      }

      if (/^T-[A-Z][0-9]{6}$/.test(v.device_id)) {
        return this.$store.state.ecgTypes[2];
      }
      
      return '';
    },
  },
  mounted() {
    // テーブル表示件数の初期読み込み
    if (this.$store.state.user.table_lines_par_page) {
      this.selectedLinesPerPage = this.$store.state.user.table_lines_par_page;
    }
    // 他の画面で表示件数が変更された場合、こちらの画面にも反映させる
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'setUserTableLinesParPage') {
        this.selectedLinesPerPage = state.user.table_lines_par_page;
      }
    });

    this.subscribeItems();
    this.subscribeNumOfKeepeds();
  },
  watch: {
    selectedLinesPerPage(lineNumber) {
      if (this.$store.state.user.table_lines_par_page != lineNumber) {
        const staffsRef = this.$db
          .collection('staffs')
          .doc(this.$store.state.user.id);
        // 運営主体管理ユーザーによる、なりすましの場合は更新しない
        if (!this.isSwitchingUser()) {
          staffsRef.update({
            table_lines_par_page: lineNumber
          });
        }
        this.$store.commit('setUserTableLinesParPage', lineNumber);
      }
    },
    searchBeginKeepStart(date) {
      if (date) {
        date = new Date(date + ' 00:00:00');
        if (date.toString() === "Invalid Date") return;
      }
      this.subscribeItems();
    },
    searchBeginKeepEnd(date) {
      if (date) {
        date = new Date(date + ' 23:59:59');
        if (date.toString() === "Invalid Date") return;
      }
      this.subscribeItems();
    },
  },
  beforeDestroy() {
    // 変更監視を停止
    if (unsubscribeItems) unsubscribeItems();
    if (unsubscribeNumOfKeepeds) unsubscribeNumOfKeepeds();
  }
}
</script>

<style scoped>
  .border-less {
    border: none !important;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    background-color: white;
    border-radius: 5px;
    margin: 0;
  }
</style>

<style>
  input[type="date"]::-webkit-calendar-picker-indicator {
    background-color: white;
    border-radius: 5px;
    margin: 0;
  }
</style>